/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding and margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

/* Set core body defaults */
body {
  font-weight: 400;
  font-style: normal;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
}

/* A basic layout container */
.container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

/* Simple grid layout */
.grid {
  display: grid;
  gap: 20px;
}

/* Remove list styles on ul, ol elements */
ul[class],
ol[class] {
  list-style: none;
}

/* A simple responsive image style */
img {
  max-width: 100%;
  height: auto;
}

/* Link styles */
a {
  text-decoration: none;
  color: inherit;
}

/* Basic button styling */
button {
  cursor: pointer;
}

/* Improve form elements */
input,
button,
textarea,
select {
  font: inherit;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}
