.menuItems a {
  background-color: black;
  font-weight: bold;
  color: white;
  text-decoration: none;
  padding: 0 20px;
  font-size: 20px;
}

.navLink:hover,
.navLink.active {
  text-decoration: underline;
}

.menuItems {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex: auto;
}

nav {
  display: flex;
  flex: auto;
}
