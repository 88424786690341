.marker-red {
  width: 24px;
  height: 24px;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23FF0000" width="24px" height="24px"%3E%3Cpath d="M12 2c-4.4 0-8 3.6-8 8 0 5.5 8 14 8 14s8-8.5 8-14c0-4.4-3.6-8-8-8zm0 11.5c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5z"/%3E%3C/svg%3E');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  cursor: pointer; /* Change cursor to pointer on hover */
}
.marker-green {
  width: 24px;
  height: 24px;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2300FF00" width="24px" height="24px"%3E%3Cpath d="M12 2c-4.4 0-8 3.6-8 8 0 5.5 8 14 8 14s8-8.5 8-14c0-4.4-3.6-8-8-8zm0 11.5c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5z"/%3E%3C/svg%3E');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  cursor: pointer; /* Change cursor to pointer on hover */
}
